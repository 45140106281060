.common-strategy-list-container {
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  flex: 1;
  gap: 2px;
  padding: 2px;
  .common-strategy-list-header {
    display: flex;
    flex-direction: row;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    gap: 2px;
    font-weight: 700;

    .common-strategy-list-header-tab {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      font-size: smaller;
      padding: 4px;
      transition: 0.2s ease all;
      opacity: 0.7;
      gap: 4px;
      &:hover,
      &.selected {
        opacity: 1;
      }
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &.disabled {
        opacity: 0.5;
        &:hover {
          opacity: 0.5;
        }
        cursor: not-allowed;
        * {
          cursor: not-allowed;
        }
      }
      border-top: 1px solid transparent !important;
    }
  }

  .common-strategy-list-section-container {
    transition: 0.2s ease all;
    border-radius: 6px;
    min-height: 40px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .common-strategy-list-centered-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .common-strategy-list-scrollable-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .strategy-series-section-container {
      display: flex;
      flex-direction: column;
      gap: 2px;
      user-select: none;
      * {
        user-select: none;
      }
      .strategy-series-section-header {
        font-size: larger;
        border-radius: 6px;
        &.collapsed {
          border-bottom-left-radius: 1px;
          border-bottom-right-radius: 1px;
        }
        display: flex;
        flex-direction: row;
        padding: 2px;
        align-items: center;
        .strategy-series-section-header-collapse-icon {
          height: 100%;
          display: flex;
          align-items: center;
          &.live-indicator {
            animation: statusIndicatorActive 6s ease-in-out infinite;
          }
        }
      }
      .strategy-series-section-body {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .strategy-version-data-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px;
          flex: 1;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .strategy-series-section-body-column {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
        .strategy-series-section-body-row {
          display: flex;
          gap: 4px;
          .strategy-version-selection-indicator {
            transition: 0.2s ease all;
            min-height: 40px;
            min-width: 8px;
            max-width: 8px;
            border-radius: 2px;
            &.live-indicator {
              animation: statusIndicatorActive 6s ease-in-out infinite;
            }
          }
          .strategy-version-papertrader-data-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: larger;
            gap: 4px;
            min-width: 100px;
          }
        }
      }
    }
  }
}

.count-up {
  font-family: "BalooBhaijaan2";
}

.common-modal-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  .common-modal-button-container {
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-content: space-evenly;
  }
}
