.compact-trading-card-container {
  transition: 0.2s ease all;
  border-radius: 4px;
  display: flex;
  padding: 2px;
  min-height: 120px;
  max-height: 150px;
  min-width: 30%;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  

  .compact-trading-card-bottom {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 4px;
    font-size: smaller;
    .compact-trading-card-bottom-column {
      display: flex;
      flex-direction: column;
      .compact-pct-change-label {
        font-size: smaller;
        opacity: 0.7;
      
      }
    }
    .holding-indicator-badge, .pending-order-indicator-badge {
      margin-left: auto;
      border-radius: 2px;
      margin-right: 5px;
      height: 15px;
      align-self: center;
      padding: 2px;
      text-transform: uppercase;
      font-size: smaller;
      border-radius: 16%;
      font-weight: 600;
      display: flex;
      align-items: center;
      user-select: none;
      transition: 0.2s ease all;
      opacity: 0;
      font-family: "BalooBhaijaan2";
  
      &.active {
        opacity: 1;
      }
      &.pulsing {
        animation: statusIndicatorActive 6s ease-in-out infinite;
      }
    }
  }
  .compact-currency-theme-color {
    opacity: 1;
    height: 50px;
    width: 50px;
    bottom: 1%;
    position: absolute;
    filter: blur(60px);
    &.holding {
      right: 1%;
    }
  }
}
