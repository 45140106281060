.strategy-version-icon-container {
  display: flex;
  align-items: center;
  border-radius: 6px;
  position: relative;
  padding: 2px;
  align-self: center;
  width: fit-content;
  gap: 10px;
  &:hover {
    .strategy-version-icon-currency-array {
      display: flex;
    }
    .strategy-version-name-label-relative {
      display: none;
    }
  }

  &.no-menu {
    cursor: default !important;
  }

  .menu-version-name-label {
    text-transform: capitalize;
    font-family: "Montserrat-LightAlt";
  }
  .strategy-version-name-label-relative {
    position: relative;
    font-family: Montserrat-LightAlt;
    text-transform: capitalize;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    font-size: smaller;
    padding: 2px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 140px;
  }

  .strategy-version-name-label {
    display: none;
    transition: 0.2s ease all;
    transition-delay: 0.5s;
    position: absolute;
    opacity: 0;
    white-space: nowrap;
    transform: translateY(-150%);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
    backdrop-filter: blur(10px);
    font-size: 12px;
    text-transform: capitalize;
    font-family: Montserrat-LightAlt;

    --webkit-backdrop-filter: blur(10px);
    z-index: 99;
  }
  &:hover {
    .strategy-version-name-label {
      display: block;
      opacity: 1;
    }
  }
}

.dimmed-label {
  overflow: hidden;
  &.version-id {
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: smaller;
  }
}
.copied-label {
  position: absolute;
  white-space: nowrap;
  z-index: 99;
}

.strategy-version-icon-currency-array {
  display: none;
}
.identicon-container {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metadata-loader-container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2%;
  top: 2%;
}

.strategy-version-permission-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2%;
  top: 5%;
  text-transform: capitalize;
  font-family: "Montserrat-LightAlt" !important;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  height: fit-content;
  border-radius: 6px;
  font-weight: 600;
}

.strategy-version-funds-distribution {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  .strategy-version-funds-distribution-row {
    display: flex;
    gap: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

.strategy-version-pool-composition-type {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.dimmed-label {
  &.live-label {
    &.pulsing {
      animation: statusIndicatorActive 6s ease-in-out infinite;
    }
  }
}