.order-profiles-editor-container {
  flex-direction: column;
  display: flex;
  gap: 4px;

  .order-profile-attempt-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 6px;
    .atr-multiplier-slider {
      min-width: 100px;
    }
  }

  .order-profiles-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .order-profiles-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.chart-column {
      min-width: 55%;
    }
  }
  
}

.order-profile-select {
  padding: 2px;
  flex: 1;
  border-radius: 4px;

  .order-profile-select-column {
    display: flex;
    gap: 2px;
    flex-direction: column;
  }
  .order-profile-select-row {
    padding: 2px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
  }

}

.order-profile-preview-target {
  flex: 1;
  transition: .2s ease all;
  justify-content: flex-start;
  gap: 6px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  max-width: 80px;
  border-radius: 6px;
  font-size: smaller;
  cursor: pointer;
  user-select: none;
  * {
    user-select: none;
    cursor: pointer;
  }
}

.order-profile-preview-popover-inner,
//.order-profile-preview-popover-target,
.order-profile-preview-popover-arrow,
.order-profile-preview-popover-popover,
.order-profile-preview-popover-wrapper,
.order-profile-preview-popover-body
 {
  background-color: transparent !important;
  backdrop-filter: blur(20px);
}