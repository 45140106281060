.chart-navigation-container {
  border-radius: 2px;
  height: 60px;
  max-width: 600px;
  display: flex;
  gap: 10px;
  flex-direction: row;

  .navigation-chart-outer-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .currencies-select-popup {
    min-height: fit-content;
    position: absolute;
    top: 110%;
    display: flex;
    flex-wrap: wrap;
    z-index: 98;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    flex-direction: row;
    height: fit-content;
    flex-wrap: wrap;
    gap: 4px;
    font-family: "BalooBhaijaan2";
    font-size: smaller;
    justify-content: space-evenly;
    overflow-y: auto;
    max-height: 400px;
    overflow-x: hidden;
    border-radius: 6px;
    .new-currency-selection-item {
      cursor: pointer;
      * {
        cursor: pointer;
      }
      transition: 0.2s ease all;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 50px;
      width: fit-content;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
  }
}
.navigation-chart-frame {
  border-radius: 6px;
}

.currency-selection-button {
  padding-left: 6px;
  padding-right: 6px;
  transition: 0.2s ease;
  height: 55px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  margin-right: 4px;
  border-radius: 6px;
}
.currency-deselect-button {
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 33px;
  border-radius: 1px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.description-child-container {
  display: flex;
  flex-direction: column;
  label {
    white-space: wrap;
  }
  font-size: .9em;
  padding: 8px;
}
