.loader-12 {
  height: 2rem;
  width: 4rem;
  padding-left: 4rem;
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #02eee1;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 50%;
    box-shadow: -0.5em 0 0 #02eee1;
    animation: loader-12 1s linear infinite;
  }
  &:after {
    top: 50%;
    bottom: 0;
    animation-delay: 0.25s;
  }
}

@keyframes loader-12 {
  0%,
  100% {
    box-shadow: -0.5em 0 0 transparent;
    background-color: #02eee1;
  }
  50% {
    box-shadow: -0.5em 0 0 #02eee1;
    background-color: transparent;
  }
}

#gray {
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
}

.circle-loader-container {
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  scale: 1.5;
  position: relative;
}

.loader-18 {
  &.small {
    scale: .6;
  }
  transition: 0.2s ease all;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 0.1em solid transparent;
    border-bottom-color: var(--loader-color);
    animation: 1.1s loader-18 linear infinite;
  }
  &:before {
    width: 0.9em;
    height: 1em;
  }
  &:after {
    width: 0.8em;
    height: 0.8em;
    animation-direction: reverse;
  }
}
#default-hidden {
  opacity: 0;
}

@keyframes loader-18 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
