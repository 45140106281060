.strategy-body-side-card-container {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 2px;
  flex-direction: column;
  margin-top: 5px;
}

.strategy-case-editor-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .strategy-case-editor-header-header {
    display: flex;
    width: 100%;
    height: fit-content;

    .editor-header {
      align-items: center;
      display: flex;
      min-width: 25%;
      width: fit-content;
      padding: 5px;
      justify-content: space-evenly;
      margin-right: 20px;
    }
  }
  .accordion-item {
    display: flex;
    flex-direction: column;
    .accordion-item-container {
      display: flex;
      flex-direction: column;
      width: fit-content;
      * {
        cursor: pointer;
      }
    }
  }

  .strategy-case-editor-lower-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    height: 20%;

    .strategy-case-currency-selection-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .strategy-case-meta-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      align-items: center;
      height: 100%;
      .frequency-control-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 5px;
        gap: 10px;
      }

      .weight-control-container {
        width: 200px;
        padding: 10px;

        .description-label {
          font-size: 12px;
        }
      }
    }
  }
}

.indicator-badge {
  margin: 2px;
}

.strategy-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  margin-top: 0.5rem;
  flex: 1 1;

  .chart-controls-container {
    gap: 10px;
    display: flex;
    width: 100%;
    padding: 6px;
    max-height: 60px;
    flex-direction: row;
    align-items: center;

    .chart-controls-button-container {
      display: flex;
      margin-left: auto;
      margin-right: 5px;
      .chart-controls-button-container-child {
        margin-left: 5px;
      }
    }

    .indicator-controls-button-container {
      display: flex;
      margin-right: 5px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;
      max-height: 100px;
      width: 60%;
    }

    .chart-controls-candle-size-chips {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 5px;
      margin-left: auto;
    }
  }
}

.chart-controls-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-width: 600px;
  padding: 10px;

  .chart-controls-modal-button-container {
    padding-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .chart-controls-modal-select-container {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
.strategy-tabs-container {
  display: flex;
  height: 2.5rem;
}
.chart-popup-container {
  display: flex;
  flex-direction: column;
  .jump-button {
    margin: 5px;
  }
  .case-generating-button-container {
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
  }
}
.suggestion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.suggestion-container-recommended {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2rem;
  width: 100%;
}

.fragment-container {
  display: inline;
  align-self: center;
  justify-content: space-evenly;
  height: inherit;
  .fragment-badge-operator,
  .fragment-badge-connector,
  .fragment-badge-number,
  .fragment-badge-price-head,
  .fragment-badge-candle,
  .fragment-badge-variable {
    margin-right: 5px;
  }
}
.fragment-badge-operator {
  font-family: "Montserrat-light" !important;
  font-weight: 600 !important;
  font-size: larger !important;
}


.add-criteria-button-container {
  display: flex;
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
  justify-content: center;
}

.react-financial-charts {
  height: 100%;
}

.indicator-popover-outer-container {
  .header {
    word-wrap: break-word;
    font-size: 12px;
    display: flex;
    align-items: center;
    //break text into multiple lines
  }
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .indicator-popover-container {
    align-items: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;

    .indicator-popover-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: fit-content;
      margin-bottom: 5px;
    }
  }
}

.indicator-dropdown-button,
.indicator-dropdown-button-enabled {
  border: 1px solid;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chart-menu-control-container {
  margin-left: auto;
  margin-right: 5px;
  .chart-menu-control {
    cursor: pointer;
    * {
      cursor: pointer;
    }
    transition: 0.2s ease all;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 2px;
    min-width: 250px;
    justify-content: flex-start;
    gap: 5px;

    .chart-control-interval-label {
      font-size: smaller;
      text-align: right;
      flex: 1;
      min-width: 150px;
    }
    .candle-size-chip {
      margin-left: auto;
      margin-top: 2px;
      margin-bottom: 2px;
      padding-top: 2px;
      transition: 0.2s ease all;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 30px;

      height: 30px;
      font-family: "BalooBhaijaan2";
      opacity: 0.5;
      font-size: larger;
      &:hover {
        opacity: 1;
      }
      border-radius: 5px;
    }
  }
}

.stonk-chart-container {
  position: relative;
  width: 100%;
  height: inherit;
  flex: 1;
  overflow: hidden;
}

.criteria-generator-outer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  .criteria-generator-container {
    border-radius: 6px;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1px;
    .criteria-generator-row {
      display: flex;
      gap: 2px;
      flex-direction: row;
    }
  }
}
.criteria-generator-popup {
  padding: 6px;
  min-height: fit-content;
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: column;
  z-index: 98;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;
  height: fit-content;
  gap: 4px;
  font-family: "BalooBhaijaan2";
  font-size: smaller;
  .criteria-generator-popup-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.criteria-generator-chip {
  display: flex;
  transition: 0.2s ease all;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &.inactive {
    opacity: .3 !important;
  }
}

.criteria-test-resource-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  min-width: 100px;
  bottom: 2%;
  left: 5%;
  min-height: 100px;
  min-width: 500px;
  backdrop-filter: blur(20px);
  z-index: 98;
  border-radius: 6px;
  padding: 4px;
  gap: 5px;
  &:hover {
    z-index: 999;
  }

  .criteria-resource-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
}

.criteria-result-container {
  display: flex;
  padding: 2px;
  border-radius: 6px;
  max-height: 100px;
  overflow-y: auto;
  max-width: 600px;
}