.indicator-control-button {
  transition: .2s ease all;
  font-size: smaller;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  padding-left: 4px;
  width: fit-content;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &.disabled {
    opacity: .5;
    &:hover {
      opacity: .5;
    }
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
}