.ratings-container {
  display: flex;
  flex-direction: row;
  max-height: 30px;
  gap: 5px;
  padding: 4px;
  justify-content: space-evenly;
  .rating-circle {
    height: 30px;
  }
  .rating-tooltip-container {
    display: flex;
    gap: 5px;
  }
}

.rating-tooltip {
  display: flex;
  flex-direction: column;
}

.rating-simple {
  //font-family: Montserrat-LightAlt;
  font-family: "Barlow-light";
  
  font-weight: 600;
}
