.criteria-overview-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 6px;
  .criteria-type-header-container {
    display: flex;
    align-items: center;
    padding: 2px;
    gap: 4px;
    .criteria-type-label {
      text-transform: capitalize;
      text-align: center;
    }
  }
}
.criteria-sub-menu-container {
  display: flex;
  align-items: center;
  .line {
    width: 10px;
    height: 1px;
  }
}

.criteria-candle-size-row {
  margin-left: 5px;
  transition: .2s ease all;
  display: flex;
  padding: 4px;
  padding-top: 0px;
}
.candle-size-criterias-container {
  gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.criteria-candle-size-header-container,
.criteria-candle-size-header-container-expanded {
  cursor: pointer;
  * {
    cursor: pointer;
  }
  padding: 4px;
  display: flex;
  align-items: center;
  .criteria-dependencies-container {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  gap: 4px;
  //justify-content: space-between;
}
