.new-case-editor-container {
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 100%;
  .case-editor-result-container {
    transition: 0.2s ease all;
    padding: 4px;
    width: 98%;
    margin-top: auto;
    height: fit-content;
    min-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
  }
}
.bottom-container {
  display: flex;
  margin-bottom: 5px;
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    .new-criteria-editor-button {
      transition: 0.2s ease all;
      border-radius: 4px;
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
    #new-criteria-editor-button-disabled {
      opacity: 0.2;
      cursor: not-allowed;
      * {
        cursor: not-allowed;
      }
    }
  }
}

.editable-criteria-fragment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.criteria-editor-container {
  display: flex;
  flex-direction: column;
  .criteria-editor-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}

.editable-criteria-fragment {
  border: 1px solid transparent;
  transition: 0.2s ease all;
  border-radius: 4px;
  display: flex;
  align-items: center;
  align-self: center;
}

.case-logic-container {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.criteria-fragment-cursor {
  width: 10px;
  border: 1px solid;
  margin-left: 5px;
  // add flashing animation
  animation: blinker 1.1s ease-in infinite;
  height: 20px;
  opacity: 0.6;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.fragment-badge-variable-input-container {
  display: flex;
}

.criteria-editor-suggestions-container {
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.operator-suggestions-item-container {
  display: flex;
  width: inherit;
  align-items: center;
  .explanation {
    margin-left: auto;
    padding-left: 10px;
  }
}

.case-editor-indicators-container {
  flex: 1;
}

.case-editor-header {
  padding: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .case-editor-label-container {
    display: flex;
    padding: 2px;
    align-items: center;
    gap: 10px;
    .new-case-editor-header-label {
      font-size: smaller;
      display: flex;
      padding: 3px;
      opacity: 0.7;
      border-radius: 4px;
    }
  }
  .case-editor-header-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    position: relative;
    .case-editor-header-menu-item {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &.disabled {
        cursor: not-allowed;
        * {
          cursor: not-allowed;
        }
      }
      &:hover {
        opacity: 1;
      }
      .case-editor-header-menu-item-sub-label {
        font-size: smaller;
        transition: 0.2s ease all;
        white-space: nowrap;
      }
    }
  }
}

.ai-series-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  .code-fragments-container {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
   

    .ai-fragment-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.code-fragment-extra-button {
  border-radius: 2px;
  padding-left: 2px;
  padding: 2px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: 0.2s ease all;
  &:hover {
    opacity: 1;
  }
}

.threshold-modifier-control-container {
  width: 99%;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  gap: 4px;
  .threshold-modifer-control-right-container {
    min-width: 30px;
    border-radius: 4px;
    .vertical-range-slider-container {
      border: 1px solid orange;
      height: 200px;
      display: flex;
    }
  }

  .threshold-chart-outer-container {
    .threshold-chart-container {
      width: 100%;
      height: 100px;
      border-radius: 6px;
    }
    .threshold-price-chart-container {
      width: 100%;
      height: 100px;
      border-radius: 6px;
    }
  }
}

.threshold-modifier-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 10px;
}

.threshold-loading-container{
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.threshold-modifier-upper-row {
  display: flex;
  padding: 4px;
  justify-content: space-between;
}

.case-control-section-container {
  margin-top: 10px;
  transition: .2s ease all;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  .case-control-section-icon{
    margin-left: 5px;
  }
  .section-collapse-icon {
    display: flex;
    align-items: center;
    height: 100%;
  }
}