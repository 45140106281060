.tw-legend {
  position: absolute;
  min-height: 10px;
  width: fit-content;
  min-width: 100px;
  max-width: 80vw;
  z-index: 3;
  
  border-radius: 6px;
  top: 0%;
  left: 0%;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  .tw-legend-item {
    &:hover {
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    display: flex;
    align-items: center;
    padding: 4px;
    gap: 4px;
    transition: .2s ease all;
    border: 1px solid transparent;
    border-radius: 6px;
    .legend-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .reveal-settings {
      transition: .2s ease all;
      opacity: 0;
      &.scale {
        transform: scaleX(0);
      }
    }
    &:hover {
      .reveal-settings {
        opacity: 1;
        &.scale {
          transform: scaleX(1);
        }
      }
    }
  }
}