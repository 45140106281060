.color-picker-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .color-picker-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
  }
}
