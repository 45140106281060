@import "../pages/page-layout.scss";
@import "../pages/home-page/homepage.scss";
@import "../pages/home-page/desktop-homepage.scss";
@import "react-toastify/dist/ReactToastify.css";

@font-face {
  font-family: "Cairo-light";
  src:
    local("Cairo-light"),
    url(../fonts/Cairo-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-lightAlt";
  src:
    local("Montserrat-lightAlt"),
    url(../fonts/Montserrat-Light-Alt1.woff2) format("truetype");
}
@font-face {
  font-family: "BalooBhaijaan2";
  src:
    local("BalooBhaijaan2"),
    url(../fonts/BalooBhaijaan2-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Nk57-monospace";
  src:
    local("Nk57-monospace"),
    url(../fonts/nk57-monospace-no-bk.ttf) format("truetype");
}
@font-face {
  font-family: "IBMPlexMono-Light";
  src:
    local("IBMPlexMono-Light"),
    url(../fonts/IBMPlexMono-Light.ttf) format("truetype");
}
@font-face {
  font-family: "IBMPlexMono-Medium";
  src:
    local("IBMPlexMono-Medium"),
    url(../fonts/IBMPlexMono-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Extros";
  src:
    local("Extros"),
    url(../fonts/Extros-Backstage.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow-light";
  src:
    local("Barlow-light"),
    url(../fonts/Barlow-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Barlow-thin";
  src:
    local("Barlow-thin"),
    url(../fonts/Barlow-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Barlow";
  src:
    local("Barlow"),
    url(../fonts/Barlow-Regular.ttf) format("truetype");
}

body {
  font-family: "Barlow-light";
}
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
@-webkit-keyframes transformer {
  from {
    -webkit-transform: scale(0.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes transformer {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate180 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(00deg);
  }
}

@keyframes neotonHighlight {
  0% {
    transform: translateY(0px);
    box-shadow:
      rgba(04, 240, 226, 0) 0px 10px 20px,
      rgba(4, 240, 226, 0) 0px 6px 6px;
  }
  20% {
    transform: translateY(-5px);
    box-shadow:
      rgba(04, 240, 226, 0.5) 0px 10px 25px,
      rgba(4, 240, 226, 0.53) 0px 6px 6px;
  }
  40% {
    transform: translateY(-2px);
    box-shadow:
      rgba(04, 240, 226, 1) 0px 10px 15px,
      rgba(4, 240, 226, 0.5) 0px 6px 6px;
  }
  50% {
    box-shadow:
      rgba(04, 240, 226, 0.3) 0px 10px 15px,
      rgba(4, 240, 226, 0.13) 0px 6px 6px;
  }
  70% {
    transform: translateY(0px);
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeRemove {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeDisplay {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}

@keyframes toGrayscale {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(100%);
  }
}

@keyframes toSubtleGrayscale {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(70%);
  }
}

@keyframes toSubtleSaturation {
  0% {
    filter: saturate(0.1);
  }
  100% {
    filter: saturate(3);
  }
}

@keyframes statusIndicatorActive {
  0% {
    opacity: 0.5;
    filter: grayscale(100%);
  }
  50% {
    opacity: 1;
    filter: grayscale(0);
  }
  100% {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes cryptoPulse {
  to {
    opacity: 0;
    transform: scale(1.14286);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(150%);
  }
  2% {
    transform: translateX(150%);
  }
  90% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-120%);
    opacity: 0;
  }
}

@keyframes fadingMarquee {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  2% {
    transform: translateX(200%);
  }
  10% {
    transform: translateX(150%);
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-120%);
    opacity: 0;
  }
}

// animate grayscale 0 to 100
@keyframes grayscaleAnimation {
  0% {
    filter: grayscale(0%);
  }
  100% {
    filter: grayscale(100%);
  }
}

.custom-tooltip {
  z-index: 99;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 5px;
  &.small {
    max-height: 20px;
    align-items: center;
    font-size: smaller;
  }
  .price-and-date-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
  .chip-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
  }
}
