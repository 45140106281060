$max-width: 1768px;

.common-icon-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 15px;
  padding: 6px;
  width: 15px;
  position: relative;
  font-size: larger;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
  .icon-button-label {
    font-family: "BalooBhaijaan2";
    opacity: 0;
    transition: 0.2s ease all;
    transition-delay: 0.1s;
    position: absolute;
    transform: translate(0%, 100%);
    &.above {
      transform: translate(0%, -100%);
    }
    &.left {
      transform: translate(-100%, 0%);
    }
    &.right {
      transform: translate(100%, 0%);
    }
    white-space: nowrap;
    user-select: none;
    z-index: 999;
    font-size: smaller;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    scale: 0;
  }
  &:hover {
    .icon-button-label {
      scale: 1;
      opacity: 1 !important;

    }
  }
}

.common-button-container {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
  border-radius: 5px;
  transition: 0.3s ease all;
  width: fit-content;
  min-width: 160px;
  &.no-min-width {
    min-width: fit-content;
  }
  user-select: none;
  cursor: pointer;
  * {
    user-select: none;
    cursor: pointer;
  }
  &.keep-width {
    min-width: fit-content !important;
  }
  // media queries
  @media (max-width: $max-width) {
    width: 150px;
    &.keep-width {
      min-width: fit-content !important;
    }
  }
  label {
    padding-left: 5px;
    padding-right: 5px;
  }
  &.compact {
    height: auto;
    //min-width: 130px;
    font-size: smaller !important;
    gap: 2px;

    label {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  .loading-container {
    justify-content: center;
    display: flex;
    align-items: center;
    .circle-loader-container {
      transform: translateX(30%) !important;
    }
    height: 100%;
    width: 50%;
    margin-bottom: 2%;
    @media (max-width: $max-width) {
      font-size: smaller;
      min-width: 50%;
    }
  }
  .left-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    font-size: larger;
  }
  .label-container {
    font-family: "BalooBhaijaan2";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-right: 3px;
    &.ellipsis {
      // Ellipsis properties
      text-overflow: ellipsis;
      white-space: nowrap; // Prevents the text from wrapping to the next line
      overflow: hidden; // Ensures that the overflowing text is hidden
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @media (max-width: $max-width) {
      font-size: smaller;
    }
  }
}

#disabled-common-button {
  opacity: 0.4;

  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }
}

.dot-1 {
  animation: pulse 2s infinite;
}
.dot-2 {
  // delay the animation by 1s
  animation: pulse 2s infinite;
  animation-delay: 0.5s;
}
.dot-3 {
  animation: pulse 2s infinite;
  animation-delay: 1s;
}

